<template>
  <div class="insights-collection-list-wrapper">
    <div class="insights-collection-list" role="list">
      <ShopCard
        v-for="shop in shops"
        :key="shop.id"
        :shop="shop"
      />
    </div>
  </div>
</template>

<script>
import ShopCard from './ShopCard.vue'

export default {
  name: 'ShopList',
  components: {
    ShopCard
  },
  props: {
    shops: {
      type: Array,
      required: true
    }
  }
}
</script>
