<template>
  <div class="insights-collection-item shop-card" role="listitem">
    <div class="insights-card-wrapper">
      <div class="insights-card-heading">
        <h5>{{ shop.name }}</h5>
        <div class="margin-bottom-30">
          <div>{{ shop.address }}</div>
        </div>
        <div v-if="shop.corrdinates.length" class="long-lat">{{ shop.corrdinates[0] }}, {{ shop.corrdinates[1] }}</div>
        <div class="margin-top-auto"></div>
        <a
          v-if ="buyGiftCardUrl"
          :href="buyGiftCardUrl"
          target="_blank"
          class="button-primary width-100 margin-bottom-10 w-button"
        >Buy gift card</a>
        <a
          v-if ="websiteUrl"
          :href="websiteUrl"
          target="_blank"
          class="button-primary sand outline width-100 w-button"
        >Visit website</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopCard',
  props: {
    shop: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const notAvailableText = 'N/A'
    const buyGiftCardUrl = props.shop.buyGiftCardUrl && props.shop.buyGiftCardUrl !== notAvailableText
      ? props.shop.buyGiftCardUrl
      : ''
    const websiteUrl = props.shop.websiteUrl && props.shop.websiteUrl !== notAvailableText
      ? props.shop.websiteUrl
      : ''
    return {
      buyGiftCardUrl,
      websiteUrl
    }
  }
}
</script>

<style>
.shop-card .insights-card-heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}
.margin-top-auto {
  margin-top: auto;
}
</style>
