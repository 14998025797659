import { ref } from 'vue'
import Airtable from 'airtable'

const base = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLE_API_KEY }).base(process.env.VUE_APP_AIRTABLE_BASE_NAME)
const tableName = 'Centres'

export default {
  getShopList: () => {
    const shops = ref([])
    base(tableName).select()
      .eachPage((records, fetchNextPage) => {
        const shopList = [...records].map((shop) => {
          const { fields } = shop
          const corrdinates = fields['Longtitude and latitude corrdinates ']?.split(',') || []
          return {
            id: fields.id,
            name: fields['Shopping centre name '],
            address: fields.Address || '',
            buyGiftCardUrl: fields['Buy gift card URL'],
            websiteUrl: fields['Website URL'],
            corrdinates
          }
        })
        shops.value.push(...shopList)
        fetchNextPage()
      }, (err) => {
        if (err) {
          console.error(err)
        }
      })
    return shops
  }
}
