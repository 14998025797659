import { storage } from '../storages/distanceStorage'

// Returns the distance in km
export function calculateDistance (pointCoordinates, shopCoordinates) {
  const cachedResult = storage.get(pointCoordinates, shopCoordinates)
  if (cachedResult) {
    return cachedResult
  }
  const [pointLatitude, pointLongitude] = convertCoordinates(pointCoordinates)
  const [shopLatitude, shopLongitude] = convertCoordinates(shopCoordinates)
  const earthRadius = 6371
  const sinDistance = Math.sqrt(haversine(shopLatitude - pointLatitude) + Math.cos(pointLatitude) * Math.cos(shopLatitude) * haversine(shopLongitude - pointLongitude))
  const distance = 2 * earthRadius * Math.asin(sinDistance)
  storage.set(pointCoordinates, shopCoordinates, distance)
  return distance
}

function versin (angle) {
  const sin = Math.sin(angle / 2)
  return 2 * sin * sin
}

function haversine (angle) {
  return versin(angle) / 2
}

function rad (angle) {
  return angle * Math.PI / 180
}

function convertCoordinates (coordinates) {
  const [latitudeString, longitudeString] = coordinates
  let latitude = Number(latitudeString)
  let longitude = Number(longitudeString)
  if (isNaN(latitude)) {
    latitude = convertDmsToDecimalDegrees(latitudeString)
  }
  if (isNaN(longitude)) {
    longitude = convertDmsToDecimalDegrees(longitudeString)
  }
  return [rad(latitude), rad(longitude)]
}

function convertDmsToDecimalDegrees (angle) {
  const matches = angle.match(/(\d+)\D+(\d+)\D+(\d+)/)
  if (matches.length > 3) {
    return Number(matches[1]) + matches[2] / 60 + matches[3] / 3600
  }
  return angle
}
