import mbxClient from '@mapbox/mapbox-sdk'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'

const baseClient = mbxClient({ accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN })
const geocodingService = mbxGeocoding(baseClient)
const storage = new Map()

async function getLocations (searchText) {
  const response = await geocodingService.forwardGeocode({
    query: searchText
  }).send()
  return response.body.features.map((location) => {
    const [longitude, latitude] = location.center
    const bbox = location.bbox
    return {
      id: location.id,
      name: location.place_name,
      corrdinates: [latitude, longitude],
      bbox: bbox ? [bbox[1], bbox[0], bbox[3], bbox[2]] : undefined
    }
  })
}

export default {
  getLocations: async (searchText) => {
    const cachedResult = storage.get(searchText)
    if (cachedResult) {
      return cachedResult
    }
    const locations = await getLocations(searchText)
    storage.set(searchText, locations)
    return locations
  }
}
