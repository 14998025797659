<template>
  <ul class="location-options" @keyup.arrow-up="focusPreviousOption" @keyup.arrow-down="focusNextOption">
    <li
      v-for="location in locations"
      :key="location.id"
      tabindex='2'
      @click="selectLocation(location)"
      @keyup.enter="selectLocation(location)"
      @keyup.space="selectLocation(location)"
    >
      {{ location.name }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LocationOptions',
  props: {
    locations: {
      type: Array,
      default: () => []
    }
  },
  emits: ['select-location'],
  setup (props, { emit }) {
    function focusPreviousOption (event) {
      if (event.target.previousSibling.tabIndex) {
        event.target.previousSibling.focus()
      }
    }
    function focusNextOption (event) {
      if (event.target.nextSibling.tabIndex) {
        event.target.nextSibling.focus()
      }
    }
    function selectLocation (location) {
      emit('select-location', location)
    }

    return {
      focusPreviousOption,
      focusNextOption,
      selectLocation
    }
  }
}
</script>

<style>
.location-options {
  position: absolute;
  width: 100%;
  padding: 0.4rem 0;
  border-radius: 15px;
  background-color: white;
  border: 2px solid #172352;
}
li {
  margin: 0.3rem 0;
  padding: 0 1rem;
  list-style: none;
  cursor: pointer;
}
li:focus {
  outline:solid 1px black;
}
li:hover {
  background-color: #ff666e;
  color: #ffffff;
}
</style>
