<template>
  <div class="search-bar-wrapper">
    <div class="tabs">
      <div class="tab">
        <label class="radio-wrapper" for="locationModeTrue">
          <input v-model="isLocationMode" :value="true" id="locationModeTrue" type="radio" tabindex="6" />
          <span class="radio-checkmark"></span>
          <span class="radio-label-text">Location</span>
        </label>
      </div>
      <div class="tab">
        <label class="radio-wrapper" for="locationModeFalse">
          <input v-model="isLocationMode" :value="false" id="locationModeFalse" type="radio" tabindex="7" />
          <span class="radio-checkmark"></span>
          <span class="radio-label-text">Shopping centre</span>
        </label>
      </div>
    </div>
    <div class="search-wrapper">
      <SearchByLocation
        v-if="isLocationMode"
        :locations="locations"
        :show-result="showResult"
        @search="search"
        @search-location="searchLocation"
        @select-location="selectLocation"
      />
      <SearchByShoppingCentre v-else :show-result="showResult" @search="searchByShop" @clear-search="clearSearch" />
    </div>
  </div>
  <div v-if="showResult" class="margin-top-50">
    <div v-if="!shopsFiltred.length" class="text-center">
      <div class="margin-bottom-15">No results found, please try a new search or expanding the radius.</div>
      <button type="button" class="button-primary w-button outline sand" @click="clearSearch">
        Clear search
      </button>
    </div>
    <ShopList :shops="shopsFiltred" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { calculateDistance } from '../helpers/haversineHelper'
import airtableService from '../services/airtableService'
import mapboxService from '../services/mapboxService'
import SearchByLocation from '../components/SearchByLocation.vue'
import SearchByShoppingCentre from '../components/SearchByShoppingCentre.vue'
import ShopList from '../components/ShopList.vue'

export default {
  name: 'ShoppingCentres',
  components: {
    SearchByLocation,
    SearchByShoppingCentre,
    ShopList
  },
  setup() {
    const isLocationMode = ref(true)
    const showResult = ref(false)
    const shops = airtableService.getShopList()
    const locations = ref([])
    const selectedLocation = ref({})
    const shopsFiltred = ref([])

    function search(radius) {
      shopsFiltred.value = selectedLocation.value.corrdinates
        ? shops.value
            .map((shop) => ({
              ...shop,
              distance: shop.corrdinates.length
                ? calculateDistance(selectedLocation.value.corrdinates, shop.corrdinates)
                : null
            }))
            .sort((shop1, shop2) => shop1.distance - shop2.distance)
            .filter((shop) => shop.distance !== null && (!radius || shop.distance <= radius))
        : []
      showResult.value = true
    }

    async function searchLocation(searchText) {
      locations.value = searchText ? await mapboxService.getLocations(searchText) : []
    }

    function selectLocation(location) {
      locations.value = []
      selectedLocation.value = location
    }

    function searchByShop(searchText) {
      shopsFiltred.value = searchText
        ? shops.value.filter((shop) => shop.name && shop.name.toLowerCase().includes(searchText.toLowerCase()))
        : []
      showResult.value = true
    }

    function clearSearch() {
      showResult.value = false
      shopsFiltred.value = []
      locations.value = []
      selectedLocation.value = {}
    }

    watch(isLocationMode, () => {
      clearSearch()
    })

    return {
      shopsFiltred,
      isLocationMode,
      search,
      searchLocation,
      selectLocation,
      searchByShop,
      clearSearch,
      locations,
      showResult
    }
  }
}
</script>

<style>
.section-large.padding-top-0 {
  padding: 0;
}
.tabs {
  display: flex;
  margin: 0 -2rem 0;
  flex-wrap: wrap;
}
.tab {
  display: flex;
  align-items: center;
  margin: 0 2rem;
}
.search-wrapper {
  display: flex;
  align-items: flex-end;
  height: 80px;
  flex-wrap: wrap;
}
.margin-top-50 {
  margin-top: 50px;
}
.text-center {
  text-align: center;
}
.search-bar-wrapper {
  max-width: 700px;
  margin: auto;
  padding: 0 1rem;

  @media (min-width: 768px) {
    padding: 0 10rem;
  }
}
</style>
