function getKey (firstPointCoordinates, seconsPointCoordinates) {
  const firstKey = `${firstPointCoordinates[0]},${firstPointCoordinates[1]}`
  const secondKey = `${seconsPointCoordinates[0]},${seconsPointCoordinates[1]}`
  return `${firstKey};${secondKey}`
}

class DistanceStorage {
  distances = new Map()

  get (firstPointCoordinates, seconsPointCoordinates) {
    const key = getKey(firstPointCoordinates, seconsPointCoordinates)
    return this.distances.get(key)
  }

  set (firstPointCoordinates, seconsPointCoordinates, value) {
    const key = getKey(firstPointCoordinates, seconsPointCoordinates)
    return this.distances.set(key, value)
  }
}

export const storage = new DistanceStorage()
