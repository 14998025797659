<template>
  <SearchInput
    v-model:searchText="searchText"
    hide-button
    placeholder="Search by shopping centre..."
  />
</template>

<script>
import { ref, watch, toRefs } from 'vue'
import SearchInput from './SearchInput.vue'

export default {
  name: 'SearchByShoppingCentre',
  components: {
    SearchInput
  },
  props: {
    showResult: {
      type: Boolean,
      default: false
    }
  },
  emits: ['search', 'clear-search'],
  setup (props, { emit }) {
    const { showResult } = toRefs(props)
    const searchText = ref('')
    let isSearchStarted = false

    watch(showResult, (newValue) => {
      if (!newValue) {
        searchText.value = ''
        isSearchStarted = false
      }
    })

    const minCharactersForSearch = 3
    watch(searchText, (newValue) => {
      if (isSearchStarted || newValue.length >= minCharactersForSearch) {
        isSearchStarted = true
        emit('search', searchText.value)
      }
      if (!newValue) {
        emit('clear-search')
      }
    })

    return {
      searchText
    }
  }
}
</script>

<style>
.search-by-shopping-centre {
  display: flex;
}
</style>
