<template>
  <div class="search-input-wrapper search-by-location">
    <SearchInput
      v-model:searchText="searchText"
      placeholder="Search by location, postal code or city…"
      ref="searchInputRef"
      @input="searchLocation"
      @search="search()"
    />
    <Popper :show="showTooltip" content="Use my location" placement="top" class="geolocation-tooltip" hover arrow>
      <button
        :disabled="loading"
        class="geolocation"
        tabindex="4"
        @click="getCurrentPosition"
        @mouseover="changeTooltipStage"
      >
        <div class="geolocation-icon" :class="geolocationIconClass">
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="25" fill="#172352" fill-opacity="0.1" />
            <path
              d="M24.9996 13.2749C22.5572 13.2778 20.2156 14.2493 18.4886 15.9764C16.7616 17.7034 15.79 20.045 15.7871 22.4874C15.7871 30.37 24.1621 36.3241 24.5187 36.5736C24.6597 36.6721 24.8276 36.7249 24.9996 36.7249C25.1716 36.7249 25.3395 36.6721 25.4805 36.5736C25.8371 36.3241 34.2121 30.37 34.2121 22.4874C34.2092 20.045 33.2376 17.7034 31.5106 15.9764C29.7835 14.2493 27.442 13.2778 24.9996 13.2749ZM25.0002 19.1377C25.6628 19.1377 26.3105 19.3342 26.8614 19.7023C27.4123 20.0704 27.8417 20.5936 28.0952 21.2057C28.3488 21.8178 28.4151 22.4914 28.2858 23.1412C28.1566 23.7911 27.8375 24.388 27.369 24.8565C26.9005 25.325 26.3036 25.6441 25.6538 25.7733C25.0039 25.9026 24.3304 25.8362 23.7182 25.5827C23.1061 25.3291 22.5829 24.8998 22.2148 24.3489C21.8467 23.7979 21.6502 23.1503 21.6502 22.4877C21.6502 21.5992 22.0032 20.7471 22.6314 20.1189C23.2597 19.4906 24.1117 19.1377 25.0002 19.1377Z"
              fill="#172352"
            />
          </svg>
        </div>
      </button>
    </Popper>
    <LocationOptions v-if="locations.length" :locations="locations" @select-location="selectLocation" />
  </div>
  <div class="form-input-wrapper radius">
    <label for="radius">Radius</label>
    <input
      v-model.number="radius"
      v-mask="radiusMask"
      type="text"
      tabindex="5"
      id="radius"
      class="form-input w-input"
    />
  </div>
</template>

<script>
import { computed, ref, watch, toRefs, nextTick } from 'vue'
import { debounce } from 'lodash'
import Popper from 'vue3-popper'
import LocationOptions from './LocationOptions.vue'
import SearchInput from './SearchInput.vue'
import { useGeolocation } from '../composables/useGeolocation'
import mask from '../directives/mask'
import { getRadiusCircumscribedСircle } from '../helpers/circumscribedСircleHelper'

export default {
  name: 'SearchByLocation',
  components: {
    LocationOptions,
    Popper,
    SearchInput
  },
  directives: {
    mask
  },
  props: {
    locations: {
      type: Array,
      default: () => []
    },
    showResult: {
      type: Boolean,
      default: false
    }
  },
  emits: ['search', 'select-location', 'search-location'],
  setup(props, { emit }) {
    const { showResult } = toRefs(props)
    const searchText = ref('')
    const searchInputRef = ref()
    const radius = ref(10)
    const radiusMask = {
      alias: 'numeric',
      suffix: ' km',
      max: 1000000
    }
    function search() {
      emit('search', radius.value)
    }

    function selectLocation(location) {
      searchText.value = location.name
      radius.value = location.bbox ? getRadiusCircumscribedСircle(location.bbox) : 10
      emit('select-location', location)
      searchInputRef.value.focusSearchButton()
    }
    const searchLocation = debounce(() => {
      emit('search-location', searchText.value)
    }, 800)

    const { getCurrentPosition, loading } = useGeolocation(searchText, radius, emit)
    const geolocationIconClass = computed(() => (loading.value ? 'geolocation-icon-animation' : ''))

    watch(showResult, (newValue) => {
      if (!newValue) {
        searchText.value = ''
      }
    })

    const showTooltip = ref(true)
    function changeTooltipStage() {
      showTooltip.value = null
    }
    const closeTooltipDelay = 4000
    setTimeout(() => {
      if (showTooltip.value) {
        showTooltip.value = false
        nextTick(changeTooltipStage)
      }
    }, closeTooltipDelay)

    return {
      searchText,
      search,
      selectLocation,
      searchLocation,
      radius,
      radiusMask,
      getCurrentPosition,
      geolocationIconClass,
      loading,
      searchInputRef,
      showTooltip,
      closeTooltipDelay,
      changeTooltipStage
    }
  }
}
</script>

<style>
.search-input-wrapper {
  position: relative;
  margin-right: 1rem;
  flex-grow: 1;
  flex-basis: 0%;
}
.search-by-location input {
  padding-right: 75px;
}
.geolocation {
  display: flex;
  height: 100%;
  padding: 0;
  background-color: transparent;
}
.geolocation:focus {
  outline: none;
}
.geolocation svg {
  width: 32px;
  height: 32px;
  opacity: 0.8;
}
.geolocation:focus svg {
  opacity: 1;
}
@keyframes ping {
  from {
    transform: scale(0.25);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0.5;
  }
}
.geolocation-icon-animation {
  animation: ping 1s ease-in-out infinite both;
}
.radius label {
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
}
.radius input {
  min-width: 150px;
}
.geolocation-icon {
  display: flex;
}
.geolocation-tooltip {
  position: absolute;
  top: 4px;
  right: 135px;
  height: 32px;
  width: 32px;
  margin: 0 !important;
  border: 0 !important;
}
.geolocation-tooltip .popper {
  min-width: 150px;
  text-align: center;
}
</style>
