<template>
  <div class="form-input-wrapper form-input-group search-input">
    <div class="form-input-group-prepend">
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27.125 27.125L20.9567 20.9458L27.125 27.125ZM24.375 12.6875C24.375 15.7872 23.1436 18.76 20.9518 20.9518C18.76 23.1436 15.7872 24.375 12.6875 24.375C9.58778 24.375 6.61502 23.1436 4.42319 20.9518C2.23136 18.76 1 15.7872 1 12.6875C1 9.58778 2.23136 6.61502 4.42319 4.42319C6.61502 2.23136 9.58778 1 12.6875 1C15.7872 1 18.76 2.23136 20.9518 4.42319C23.1436 6.61502 24.375 9.58778 24.375 12.6875V12.6875Z"
          stroke="#172352"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <input v-model="searchTextComputed" v-bind="$attrs" tabindex="1" type="text" class="form-input w-input" />
    <div v-if="!hideButton" class="form-input-group-append">
      <button type="button" tabindex="3" class="button-primary w-button" ref="searchButtonRef" @click="search()">
        Search
      </button>
    </div>
  </div>
</template>

<script>
import { computed, toRefs, ref } from 'vue'

export default {
  name: 'SearchInput',
  props: {
    searchText: {
      type: String,
      required: true,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['search', 'update:searchText'],
  setup(props, { emit }) {
    const searchButtonRef = ref()
    const { searchText } = toRefs(props)
    const searchTextComputed = computed({
      get: () => searchText.value,
      set: (value) => {
        emit('update:searchText', value)
      },
    })

    function search() {
      emit('search')
    }

    function focusSearchButton() {
      searchButtonRef.value.focus()
    }

    return {
      searchTextComputed,
      search,
      focusSearchButton,
      searchButtonRef,
    }
  },
}
</script>

<style>
.search-input {
  position: relative;
  width: 100%;
}
.search-input input {
  flex-grow: 1;
  flex-basis: 0%;
  padding-right: 30px;
  padding-left: 40px;
  min-width: 200px;
}
.form-input-group {
  display: flex;
  align-items: stretch;
}
.form-input-group-append button {
  padding: 9px 32px 8px;
  margin-left: -23px;
}
.form-input-group-prepend {
  position: absolute;
  left: 10px;
  top: 12px;
  display: flex;
}
.form-input-group-prepend svg {
  width: 16px;
  height: 16px;
}
</style>
