import { ref } from 'vue'

export function useGeolocation (searchText, radius, emit) {
  const loading = ref(false)
  const options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 30000
  }

  function success (position) {
    loading.value = false
    const currentCorrdinates = [position.coords.latitude, position.coords.longitude]
    searchText.value = 'My Location'
    emit('select-location', {
      corrdinates: currentCorrdinates
    })
    radius.value = 10
    emit('search', radius.value)
  }

  function getCurrentPosition () {
    loading.value = true
    navigator.geolocation.getCurrentPosition(success, () => {}, options)
  }

  return {
    getCurrentPosition,
    loading
  }
}
